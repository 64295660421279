.list {
  margin: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-display {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
