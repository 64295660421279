.button {
    display: inline-block;
    width: 100px;
    background: #ffbf69;
    padding: 8px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    color: black
}
